/* Custom styles below */
@import 'custom/iframe';
@import 'custom/newsletter';

.pp-title {
    &:before {
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #c2c2c2;
        top: 50%;
    }

    h2 {
        background-color: #fff;
        display: inline-block;
        z-index: 1;
        font-size: 2.5em;
        font-weight: 200;
        color: #161616;

        .cloudcontentset.color-primary & {
            background-color: $primary-color;
        }
        .cloudcontentset.color-secondary & {
            background-color: $secondary-color;
        }
        .cloudcontentset.color-text & {
            background-color: $text-color;
        }
        .cloudcontentset.color-background & {
            background-color: $background-color;
        }
    }

}

.form-mandatory {
    color: #09aeae;
    font-size:10px;
    vertical-align: super;
}

.navbar-header.has-bg-color.color-background .submenu:hover {
    background: #fff !important;

}

.navbar-header .submenu li {
    &:hover {
        background: $secondary-color;
    }
}

.steps-list{
    list-style: none;
    counter-reset: a;
    padding: 0;
    text-align: center;
    li{
        .content-step{
            background-color: #fff;
            max-width: 120px!important;
            margin: 0 auto;
            &:after {
                content: "";
                display: block;
                width: 124px;
                height: 110px;
                position: absolute;
                top: 0;
                left: 215px;
                background: url(../media/bg-dots-steps.gif) repeat-x 50%;
            }

        }
        .fa{
            width: 110px;
            height: 110px;
            line-height: 110px;
            font-size: 3em;
            border-radius: 100%;
            border: 1px solid #09aeae;
            color: #09aeae;
            margin-bottom: .3em;
            text-rendering: auto;
            transform: translate(0);
        }
        &:last-child {
            .content-step{
                &:after{content:none}
            }
        }
    }
}


.col-sm-3{
    float: left;
    width: 25%;
}

.navbar-brand {
        height: auto!important;
    }

body{
    font-family: $font-family-roboto;
    font-weight: 300;
}

.picto-left {
    .rte-text{
        ul{
            list-style: none;
            li{
                &:before{
                    font: normal normal normal 14px/1 FontAwesome;
                    text-rendering: auto;
                    transform: translate(0);
                    content: "\f00c";
                    width: 15px;
                    margin-left: -20px;
                    display: block;
                    float: left;
                    font-size: .7em;
                    line-height: 1.8em;
                    color: #09aeae;
                }
            }
        }
    }
}

.bb-site-wrapper {
    .main {
        padding-top: 76px;
    }
}

b, strong {
    font-weight: 700;
}

.navbar-header {
    text-transform: uppercase;

    a.nav-link {
        @include transition($ui-transition);

        &:hover {
            text-decoration: none !important;
            color: lighten($text-color, 80%);
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-item {
            margin: 0 .5rem;

            &:after {
                content: '';
                display: block;
                width: 0%;
                height: 2px;
                background: $primary-color;
                @include transition(width 500ms ease-in-out);
            }

            &.active,
            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

.paragraph {
  h3, h4, h5 {
    font-size: 1.75rem;
  }
}
